import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../../community-info-card/community-info-card.component';
import {DomSanitizer} from '@angular/platform-browser';
import {MapsService} from '../../../../core/services/maps.service';

@Component({
    selector: 'app-virtual-modal',
    templateUrl: './virtual-modal.component.html',
    styleUrls: ['./virtual-modal.component.scss']
})
export class VirtualModalComponent {
    virtualTour;
    fullScreen = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
                public sanitizer: DomSanitizer,
                public mapsService: MapsService) {
        if (this.data.virtual_tours[0]) {
            this.virtualTour = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.virtual_tours);
        }
        // else if (this.data.id === 5 || this.data.id === 3 || this.data.id === 8) {
        //     this.virtualTour = this.sanitizer.bypassSecurityTrustResourceUrl('http://360.visengine.com/meraas_vrt/v1_9/index1.html#pano16/150.0/0.0/90.0');
        // }
    }
}
