import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './components';
import {MaterialModule} from './material.module';

import {MapInitDirectiveDirective} from './directives/map-init-directive';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MapFilterComponent} from './components/map-filter/map-filter.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommunityInfoCardComponent} from './components/community-info-card/community-info-card.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import {MatExpansionModule} from '@angular/material/expansion';
import {VirtualModalComponent} from './components/modals/virtual-modal/virtual-modal.component';
import {GalleryModalComponent} from './components/modals/gallery-modal/gallery-modal.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { HundredthPipe } from './pipes/hundredth.pipe';

@NgModule({
    declarations: [
        HeaderComponent,
        MapInitDirectiveDirective,
        MapFilterComponent,
        CommunityInfoCardComponent,
        VirtualModalComponent,
        GalleryModalComponent,
        HundredthPipe,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FlexLayoutModule,
        FormsModule,
        IvyCarouselModule,
        FormsModule,
        MatExpansionModule,
        MatSnackBarModule,
        ReactiveFormsModule,
    ],
    exports: [
        MaterialModule,
        HeaderComponent,
        MapInitDirectiveDirective,
        FlexLayoutModule,
        MapFilterComponent,
        CommunityInfoCardComponent,
        MatSnackBarModule,
        HundredthPipe
    ],
})
export class SharedModule {
}
