<app-header (filterEvent)="filterEvent($event)"
            [projectDetailsOpen]="mapsService.showProjectDetails">
</app-header>
<div class="main-content"
     [ngStyle]="mapsService.deviceUsed ==='mobile' && {'height': mapsService.deviceHeight-65+'px'}">
    <div [appMapInitDirective]="locations"
         [mapOptions]="mapOptions"
         [projectsData]="projectsData"
         [isDisplayTraffic]="displayTraffic"
         [isDisplayRadius]="displayRadius"
         [isDisplayDistance]="displayDistance"
         [projectDetailsOpen]="mapsService.showProjectDetails"
         (isDisplayTrafficOut)="isDisplayTrafficOutFunc($event)"
         (isDisplayRadiusOut)="isDisplayRadiusOutFunc($event)"
         (isDisplayDistanceOut)="isDisplayDistanceOutFunc($event)"
         (projectDetailsOpenOut)="projectDetailsOpenOutFunc($event)"
         (projectDetailsCheckedOut)="projectDetailsCheckedOut($event)"
         class="map">
    </div>
    <app-map-filter [projectTitle]="title"
                    (filterEvent)="filterEvent($event)"
                    (showCommunity)="showCommunity($event)"
                    (projectDetailsCheckedOut)="projectDetailsCheckedOut($event)"
                    [projectsData]="projectsData"
                    [displayRadius]="displayRadius"
                    [displayDistance]="displayDistance"
                    [projectDetailsOpen]="mapsService.showProjectDetails"
                    [visibleFilter]="visibleFilter">
    </app-map-filter>

    <mat-card class="example-card" *ngIf="locationDetails && locationDetails.id && locationDetails.images">
        <div class="example-card-wrap">
            <img class="example-card-wrap_img" mat-card-image [src]="locationDetails.images[0]"
                 alt="Photo of a {{locationDetails.title}}">
            <img class="close-btn" src="assets/img/close_blue.png" routerLink="/dashboard">
        </div>
        <div class="example-card_content">
            <mat-card-header *ngIf="locationDetails.title || locationDetails.labelContent">
                <mat-card-title>{{locationDetails.title ? locationDetails.search_title : locationDetails.labelContent}}</mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="locationDetails.description">
                <p>{{locationDetails.description}}</p>
            </mat-card-content>
        </div>
        <img class="close-btn-mobile" src="assets/img/close_blue.png" routerLink="/dashboard">
    </mat-card>
    <app-community-info-card id="community-card" *ngIf="mapsService.showProjectDetails"
                             [projectData]="projectData"
                             [developersType]="developersType">
    </app-community-info-card>
</div>
<div class="background-opacity"
     routerLink="/dashboard"
     (click)="mapsService.resetMapFunc()"
     *ngIf="mapsService.blurShow">
</div>
<footer>
    <button (click)="openOriginSite()">© DHRE by VisEngine</button>
</footer>
