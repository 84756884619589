import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';

@Component({
    selector: 'app-request-modal',
    templateUrl: './request-modal.component.html',
    styleUrls: ['./request-modal.component.scss']
})
export class RequestModalComponent implements OnInit {


    constructor(@Inject(MAT_DIALOG_DATA) public data: { name: '' },
                private http: HttpClient,
                private dialogRef: MatDialogRef<RequestModalComponent>,
                private snackBar: MatSnackBar) {
    }

    ngOnInit(): void {

    }


}
