import {Injectable} from '@angular/core';
import {ProjectsModel} from '../../models/ProjectsModel';
import {MapsService} from './maps.service';
import {MapsDataService} from './maps-data.service';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class MapFilterService {
    public projectsData;
    public displayRadius = false;
    public displayDistance = false;
    public filterProperties;
    public showProperty = false;
    public allPlotsChecked: boolean;
    public allPropertyTypesChecked: boolean;
    public filterEvent;
    public filterEventSubject = new Subject();
    public radiusSelected = 0;
    public listLocations = [];
    autoTicks = false;
    disabled = false;
    invert = false;
    max = 30;
    min = 0;
    showTicks = false;
    step = 5;
    thumbLabel = false;
    vertical = false;
    public plotTypeFilter = [
        {checked: false, value: 4, type: 'landmark'},
        {checked: false, value: 5, type: 'education'},
        {checked: false, value: 6, type: 'shopping'},
        {checked: false, value: 7, type: 'health'},
        {checked: false, value: 8, type: 'transport'},
    ];
    public projectTypeFilter = [
        {checked: false, value: 1, type: 'apartments'},
        {checked: false, value: 2, type: 'townhouses'},
        {checked: false, value: 3, type: 'land'},
    ];
    public propertyTypesFilter = [
        {checked: false, value: 1, type: 'moveIn'},
        {checked: false, value: 2, type: 'offPlan'},
        {checked: false, value: 3, type: 'landPlots'},
        {checked: false, value: 4, type: 'readyToConstruct'},
    ];
    public developersFilter: any[] = [
        {checked: false, type: 1},
        {checked: false, type: 2},
        {checked: false, type: 3},
    ];

    constructor(
        public mapsService: MapsService,
        public mapsDataService: MapsDataService,
    ) {
    }

    radiusSlider(): void {
        this.displayRadius = this.radiusSelected !== 0;
        this.mapsService.radiusRange.next(this.radiusSelected * 1000);
    }

    public hideDistance(): void {
        this.mapsService.resetMap.next('distance');
    }

    public closeRadius(): void {
        this.displayRadius = false;
        this.filterEventSubject.next(this.makeAllFilters());
    }

    resetToDefault(): void {
        this.plotTypeFilter = [
            {checked: false, value: 4, type: 'landmark'},
            {checked: false, value: 5, type: 'education'},
            {checked: false, value: 6, type: 'shopping'},
            {checked: false, value: 7, type: 'health'},
            {checked: false, value: 8, type: 'transport'},
        ];
        this.projectTypeFilter = [
            {checked: true, value: 1, type: 'apartments'},
            {checked: true, value: 2, type: 'townhouses'},
            {checked: true, value: 3, type: 'land'},
        ];
        this.propertyTypesFilter = [
            {checked: false, value: 1, type: 'moveIn'},
            {checked: false, value: 2, type: 'offPlan'},
            {checked: true, value: 3, type: 'landPlots'},
            {checked: true, value: 4, type: 'readyToConstruct'},
        ];
        this.developersFilter = [
            {checked: true, type: 1},
            {checked: true, type: 2},
            {checked: true, type: 3},
        ];
    }

    setProjectFilters(): void {
        setTimeout(() => {
            this.filterProperties.project_filter.forEach((item) => {
                this.developersFilter[item - 1].checked = true;
            });
            this.mapsService.selectedFilter = this.filterProperties.project_filter;
        });

        this.filterEventSubject.next(this.makeAllFilters());
    }

    onValChange(type, checked): void {
        if (checked && this.filterProperties.project_filter.indexOf(type) === -1) {
            this.filterProperties.project_filter.push(type);
        } else {
            this.filterProperties.project_filter.splice(this.filterProperties.project_filter.indexOf(type), 1);
        }
        if (this.filterProperties.project_filter[0] == null) {
            this.filterProperties.project_filter = [1, 2, 3];
        }
        this.setProjectFilters();
    }

    checkIfPropertyShow(): void {
        this.showProperty = this.mapsService.deviceUsed === 'mobile' && (this.displayRadius || this.displayDistance);
        this.filterProperties.show_radius = this.displayRadius;
        this.filterProperties.show_distance = this.displayDistance;
    }

    makeAllFilters(): any {
        const projectLocations: ProjectsModel[] = this.filterProjectType(this.projectsData);
        const landPlots: ProjectsModel[] = this.filterLandPlot(projectLocations);
        const locations: ProjectsModel[] = this.filterPropertyType(landPlots);
        this.filterEvent = {
            locations,
            traffic: this.plotTypeFilter[4].checked,
            radius: this.displayRadius,
            distance: this.displayDistance
        };
        this.setSelectedParameters();
        this.setLocalStorage();
        this.listLocations = locations;
        return this.filterEvent;
    }

    setSelectedParameters(): void {
        let propertyTypesFilter = [];
        let projectTypeFilter = [];
        let plotTypeFilter = [];
        this.displayRadius = this.filterProperties.show_radius = this.displayRadius;
        this.displayDistance = this.filterProperties.show_distance = this.displayDistance;

        this.propertyTypesFilter.forEach(item => {
            const index = propertyTypesFilter.indexOf(item.value);
            if (item.checked && index === -1) {
                propertyTypesFilter.push(item.value);
            } else if (!item.checked && index) {
                propertyTypesFilter = propertyTypesFilter.splice(index, 0);
            }
        });
        this.filterProperties.property_type = propertyTypesFilter;
        this.filterProperties.project_type = [];
        this.projectTypeFilter.forEach(item => {
            const index = projectTypeFilter.indexOf(item.value);
            if (item.checked && index === -1) {
                projectTypeFilter.push(item.value);
            } else if (!item.checked && index === 1) {
                projectTypeFilter = projectTypeFilter.splice(index, 0);
            }
        });
        this.plotTypeFilter.forEach(item => {
            const index = plotTypeFilter.indexOf(item.value);
            if (item.checked && index === -1) {
                plotTypeFilter.push(item.value);
            } else if (!item.checked && index === 1) {
                plotTypeFilter = plotTypeFilter.splice(index, 0);
            }
        });
        this.filterProperties.project_type = projectTypeFilter.concat(plotTypeFilter);
        this.checkIfPropertyShow();
    }

    setLocalStorage(): void {
        this.mapsDataService.getProjectsData(this.filterProperties);
        localStorage.setItem('filters', JSON.stringify(this.filterProperties));
    }

    filterProjectType(locations: ProjectsModel[]): ProjectsModel[] {
        let projects = Object.keys(this.developersFilter)
            .map((key) => {
                if (this.developersFilter[key].checked) {
                    return this.developersFilter[key].type;
                } else {
                    return;
                }
            }).filter((item) => item);
        let ids = Object.keys(this.projectTypeFilter)
            .map((key) => {
                if (this.projectTypeFilter[key].checked) {
                    return this.projectTypeFilter[key].value;
                } else {
                    return;
                }
            }).filter((item) => item);
        if (ids[0] == null) {
            this.projectTypeFilter = [
                {checked: true, value: 1, type: 'apartments'},
                {checked: true, value: 2, type: 'townhouses'},
                {checked: true, value: 3, type: 'land'},
            ];
            ids = [1, 2, 3];
        }
        if (projects[0] == null) {
            this.developersFilter = [
                {checked: false, type: 1},
                {checked: false, type: 2},
                {checked: false, type: 3},
            ];
            projects = [1, 2, 3];
        }

        if (ids[1] == null && ids[0] === 3) {
            this.propertyTypesFilter = [
                {checked: false, value: 1, type: 'moveIn'},
                {checked: false, value: 2, type: 'offPlan'},
                {checked: true, value: 3, type: 'landPlots'},
                {checked: true, value: 4, type: 'readyToConstruct'},
            ];
            this.mapsService.blockPropertyTypes = true;
        } else {
            this.mapsService.blockPropertyTypes = false;
        }
        if (ids.length === 0) {
            return (locations);
        } else {
            const filteredLocations = locations.filter((item: ProjectsModel) =>
                ids.includes(item.type) && projects.includes(item.project)
            ) as ProjectsModel[];
            return (filteredLocations);
        }
    }

    filterLandPlot(locations: ProjectsModel[]): ProjectsModel[] {
        this.allPlotsChecked = this.isLandPlotsAllFilter;
        const ids = Object.keys(this.plotTypeFilter)
            .map((key) => {
                if (this.plotTypeFilter[key].checked) {
                    return this.plotTypeFilter[key].value;
                } else {
                    return;
                }
            })
            .filter((item) => item);

        if (ids.length === 0) {
            return (locations);
        } else {
            const filteredLocations = this.projectsData.filter((item: ProjectsModel) =>
                ids.includes(item.type)
            ) as ProjectsModel[];

            locations.push(...filteredLocations);
            return (locations);
        }
    }

    filterPropertyType(locations: ProjectsModel[]): ProjectsModel[] {
        this.allPropertyTypesChecked = this.isPropertyTypesAllFilter;
        const ids = Object.keys(this.propertyTypesFilter)
            .map((key) => {
                if (this.propertyTypesFilter[key].checked) {
                    return this.propertyTypesFilter[key].value;
                } else {
                    return;
                }
            })
            .filter((item) => item);
        if (ids.length === 0) {
            return (locations);
        } else {
            const filteredLocations = locations.filter((item: ProjectsModel) => {
                item.lat = +item.lat;
                item.lng = +item.lng;
                return ids.includes(item.property_type);
            }) as ProjectsModel[];
            return (filteredLocations);
        }
    }

    get isLandPlotsAllFilter(): boolean {
        return (
            Object.keys(this.plotTypeFilter).every(
                (key) => this.plotTypeFilter[key].checked
            ) && this.plotTypeFilter[4].checked
        );
    }

    get isPropertyTypesAllFilter(): boolean {
        return Object.keys(this.propertyTypesFilter).every(
            (key) => this.propertyTypesFilter[key].checked
        );
    }
}
