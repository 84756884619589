import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProjectsModel} from '../../models/ProjectsModel';

@Injectable({
    providedIn: 'root'
})

export class MapsDataService {
    private url = environment.BACK_END_URL;
    public projectDataSubject = new Subject();
    private landmarksImages = [];

    constructor(private http: HttpClient,
    ) {
        this.getLandmarksDataImages();
    }

    getLandmarksDataImages(): void {
        if (this.landmarksImages[0] == null) {
            this.http.get(`${this.url}projects_locations/?type=4`).subscribe((resp: ProjectsModel[]) => {
                resp.forEach((item, index) => {
                    this.http.get(item.marker_image, {responseType: 'blob'});
                    this.landmarksImages[index] = new Image();
                    this.landmarksImages[index].src = item.marker_image;
                });
            });
        }
    }

    getProjectsData(parameters): any {
        let params = ``;
        if (parameters) {
            params = `?`;
            if (parameters.project_filter) {
                params += `project=${parameters.project_filter}&`;
            }
            if (parameters.project_type) {
                params += `type=${parameters.project_type}&`;
            }
            if (parameters.property_type) {
                params += `property_type=${parameters.property_type}&`;
            }
        }
        return this.http.get(`${this.url}projects_locations/${params}`).pipe(map((resp: ProjectsModel[]) => {
            return resp.map(item => {
                item.lat = +item.lat;
                item.lng = +item.lng;
                item.lat_def = +item.lat_def;
                item.lng_def = +item.lng_def;
                item.lat_mid = +item.lat_mid;
                item.lng_mid = +item.lng_mid;
                if (item.lat === 0.00000000 && item.lng === 0.00000000) {
                    item.lat = null;
                    item.lng = null;
                }
                if (item.lat_def === 0.00000000 && item.lng_def === 0.00000000) {
                    item.lat_def = null;
                    item.lng_def = null;
                }
                if (item.lat_mid === 0.00000000 && item.lng_mid === 0.00000000) {
                    item.lat_mid = null;
                    item.lng_mid = null;
                }
                if (item.pk === 9 ||
                    item.pk === 138 || item.pk === 77 || item.pk === 76 ||
                    item.pk === 75 || item.pk === 74 || item.pk === 70 ||
                    item.pk === 81 || item.pk === 80 || item.pk === 79 ||
                    item.pk === 78 || item.pk === 136 || item.pk === 135 ||
                    item.pk === 134 || item.pk === 82 || item.pk === 137 ||
                    item.pk === 133 || item.pk === 132 || item.pk === 131 ||
                    item.pk === 27 || item.pk === 29 || item.pk === 3 || item.pk === 78) {
                    item.lat_def = 0;
                    item.lng_def = 0;
                }
                return item;
            });
        })).subscribe(resp => {
            this.landmarksImages = [];
            this.projectDataSubject.next(resp);
        });
    }

    getProject(id): any {
        return this.http.get(`${this.url}projects_locations/${id}/`);
    }
}
