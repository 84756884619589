<mat-card class="card" *ngIf="projectData">
    <div class="mat-card-header_wrap">
        <mat-card-header>
            <img class="logo-img" *ngIf="projectData.logo != null" src="{{projectData.logo}}" alt="">
            <div class="meraas-logo" *ngIf="projectData.logo == null && projectData.project === 1"></div>
            <div class="dp-logo" *ngIf="projectData.logo == null && projectData.project === 3"></div>
            <div class="dh-logo" *ngIf="projectData.logo == null && projectData.project === 2"></div>
            <img class="close-btn"
                 src="../../../../assets/img/close.png"
                 routerLink="/dashboard" alt="" (click)="mapsService.blurShow = false">
        </mat-card-header>
    </div>
    <img mat-card-image *ngIf="!(mapsService.deviceUsed == 'mobile' && !showCommunityCard)"
         src="{{projectData.images[0] != null?projectData.images[0]:'/assets/img/bg-for-gelary.png'}}"
         (click)="openDialog(projectData)">
    <mat-card-content>
        <div class="community-card"
             *ngIf="showCommunityCard">
            <div class="card-container">
                <div class="property-type-block">
                    <span *ngIf="propertyType">{{propertyType}}</span>
                    <h5>{{projectData.search_title}}</h5>
                    <h4>
                        by {{developersType === 1 ? 'Meraas' : developersType === 3 ? 'Dubai Properties' : 'Dubai Holding'}}</h4>
                </div>
                <div class="price-ranges-block">
                    <div class="property-type-block" *ngIf="rangesShow">
                    <span title="{{rangeBetween != null ? 'Ranges between:' : rangeBetween | hundredth}}"
                          *ngIf="rangeBetween != null && rangeBetween.length > 1">{{rangeBetween != null ? 'Ranges between' : rangeBetween}}</span>
                        <h5 *ngIf="rangeBetween != null"
                            title="{{rangeBetween}}">{{rangeBetween | hundredth}}</h5>
                    </div>
                    <div class="property-type-block"
                         *ngIf="projectData.starting_price != null && projectData.starting_price>0">
                        <span>Price</span>
                        <h5>AED {{projectData.starting_price | hundredth}}</h5>
                    </div>
                </div>
            </div>
            <p>{{projectData.description}}</p>
            <div class="wrap_btn"
                 [ngStyle]="(projectData.virtual_tours[0].length > 0 && projectData.original_link) && {'justify-content': 'space-between'}">
                <button class="btn btn_gallery virtual-tour" (click)="openVirtualDialog()"
                        *ngIf="projectData.virtual_tours[0].length > 0">
                    <img src="assets/img/virtual-tour-icon.svg" alt="">
                    VIRTUAL TOUR
                </button>
                <button *ngIf="projectData.original_link" class="btn btn_requst" (click)="openRequestDialog(true)">Learn
                    More
                </button>
            </div>
        </div>
        <div class="request-card" *ngIf="!showCommunityCard">
            <form *ngIf="!showMessage" novalidate [formGroup]="requestForm">
                <div class="form-field">
                    <mat-form-field>
                        <mat-label>Full Name *</mat-label>
                        <input matInput formControlName="full_name">
                    </mat-form-field>
                    <div class="error-block">
                        <small *ngIf="requestForm.controls.full_name.hasError('required') && requestForm.get('full_name').touched">*
                            Field is required</small>
                    </div>
                </div>
                <div class="form-field">
                    <mat-form-field>
                        <mat-label>Phone Number *</mat-label>
                        <input type="tel" matInput formControlName="phone_number">
                    </mat-form-field>
                    <div class="error-block">
                        <small *ngIf="requestForm.controls.phone_number.hasError('required')  && requestForm.get('phone_number').touched">*
                            Field is required</small>
                        <small *ngIf="requestForm.controls.phone_number.hasError('minlength')  && requestForm.get('phone_number').touched">Enter
                            a valid Phone Number</small>
                    </div>
                </div>
                <div class="form-field">
                    <mat-form-field>
                        <mat-label>Email *</mat-label>
                        <input matInput formControlName="email">
                    </mat-form-field>
                    <div class="error-block">
                        <small *ngIf="requestForm.controls.email.hasError('required')  && requestForm.get('email').touched">*
                            Field is required</small>
                        <small *ngIf="requestForm.controls.email.hasError('email')  && requestForm.get('email').touched">Enter
                            a valid email</small>
                    </div>
                </div>
            </form>
            <div class="success-message-block" *ngIf="showMessage">
                <img src="assets/img/successfully-sent.svg" alt="">
                <h3>Thanks, request was <br>sent successfully</h3>
            </div>
            <div class="wrap_btn" [ngStyle]="!showMessage && {'justify-content': 'space-around'}">
                <button class="btn_requst"
                        (click)="openRequestDialog(false)">{{showMessage ? 'Back to Map' : 'Cancel'}}</button>
                <button class="btn_requst" *ngIf="!showMessage" (click)="submitData()">SEND</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
