import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MapsService} from 'src/app/core/services/maps.service';
import {MapFilterService} from '../../../core/services/map-filter.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    @Input() projectTitle: string;
    @Output() filterEvent = new EventEmitter();
    @Input() projectDetailsOpen: boolean;

    constructor(
        public mapsService: MapsService,
        public mapFilterService: MapFilterService,
    ) {
    }

    public checkProjectType(): void {
        if (!this.mapFilterService.displayDistance) {
            this.mapFilterService.hideDistance();
        }
        this.mapFilterService.checkIfPropertyShow();
        this.filterEvent.next(this.mapFilterService.makeAllFilters());
    }

    public checkProperty(): void {
        this.mapsService.resetMap.next(true);
        this.mapFilterService.displayRadius = false;
        this.mapFilterService.displayDistance = false;
        this.mapFilterService.checkIfPropertyShow();
    }
}
