import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../../community-info-card/community-info-card.component';
import {MapsService} from '../../../../core/services/maps.service';

@Component({
    selector: 'app-modals',
    templateUrl: './gallery-modal.component.html',
    styleUrls: ['./gallery-modal.component.scss']
})
export class GalleryModalComponent implements OnInit {
    public defaultSlides = [
        {path: 'assets/img/test-carusel/1.jpeg'},
        {path: 'assets/img/test-carusel/2.jpeg'},
        {path: 'assets/img/test-carusel/3.jpeg'},
        {path: 'assets/img/test-carusel/1.jpeg'},
        {path: 'assets/img/test-carusel/2.jpeg'},
        {path: 'assets/img/test-carusel/3.jpeg'},
        {path: 'assets/img/test-carusel/1.jpeg'},
        {path: 'assets/img/test-carusel/2.jpeg'},
        {path: 'assets/img/test-carusel/3.jpeg'},
    ];
    public slides = [];
    public autoplay = true;
    fullScreen = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
                private mdr: MatDialogRef<GalleryModalComponent>,
                public mapsService: MapsService) {
        this.autoplay = this.data.autoplay;
        if (this.data.imagesArray) {
            this.slides = this.data.imagesArray.map(item => {
                return {path: item};
            });
        } else {
            this.slides = this.defaultSlides;
        }
    }

    public CloseDialog(): void {
        this.mdr.close(false);
    }

    ngOnInit(): void {
    }

    openFullScreen(): void {
        this.fullScreen = !this.fullScreen;
        this.autoplay = !this.fullScreen;
    }
}
