<div class="header">
    <div class="header-left">
        <a class="route" routerLink="/dashboard" (click)="mapsService.resetMapFunc()">
            <img src="assets/img/logos/dh-logo-header.png" alt="">
        </a>
        <h1 *ngIf="projectTitle">{{projectTitle}}</h1>
    </div>
    <div class="header-controls">
        <div class="map-filter-property" *ngIf="mapsService.deviceUsed === 'desktop'">
            <h5>Developers:</h5>
            <div class="checkboxes">
                <mat-checkbox color="primary" [(ngModel)]="mapFilterService.developersFilter[1].checked"
                              [ngModelOptions]="{standalone: true}"
                              [ngClass]="{'checkbox-active': mapFilterService.developersFilter[1].checked}"
                              (ngModelChange)="mapFilterService.onValChange(2,mapFilterService.developersFilter[1].checked)">
                    Dubai Holding
                </mat-checkbox>
                <mat-checkbox color="primary" [(ngModel)]="mapFilterService.developersFilter[2].checked"
                              [ngModelOptions]="{standalone: true}"
                              [ngClass]="{'checkbox-active': mapFilterService.developersFilter[2].checked}"
                              (ngModelChange)="mapFilterService.onValChange(3,mapFilterService.developersFilter[2].checked)">
                    Dubai Properties
                </mat-checkbox>
                <mat-checkbox color="primary" [(ngModel)]="mapFilterService.developersFilter[0].checked"
                              [ngModelOptions]="{standalone: true}"
                              [ngClass]="{'checkbox-active': mapFilterService.developersFilter[0].checked}"
                              (ngModelChange)="mapFilterService.onValChange(1,mapFilterService.developersFilter[0].checked)">
                    Meraas
                </mat-checkbox>
            </div>
        </div>
        <div class="radius-block" *ngIf="mapsService.deviceUsed === 'desktop' || mapsService.deviceUsed === 'tablet'">
            <div class="radius-box">
                <span>Radius</span>
                <mat-slider [disabled]="mapFilterService.displayDistance || mapFilterService.disabled"
                            [invert]="mapFilterService.invert"
                            [max]="mapFilterService.max"
                            [min]="mapFilterService.min"
                            [step]="mapFilterService.step"
                            [thumbLabel]="mapFilterService.thumbLabel"
                            [(ngModel)]="mapFilterService.radiusSelected"
                            (ngModelChange)="mapFilterService.radiusSlider()"
                            [vertical]="mapFilterService.vertical"
                            aria-labelledby="example-name-label">
                </mat-slider>
                <label class="example-value-label">{{mapFilterService.radiusSelected}} KM</label>
            </div>
            <div>
                <span>Show Distance</span>
                <mat-slide-toggle
                        color="primary"
                        [(ngModel)]="mapFilterService.displayDistance"
                        [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="checkProjectType();"
                        [disabled]="mapFilterService.displayRadius || projectDetailsOpen"
                        class="radius">
                </mat-slide-toggle>
                <span class="wrap_close" style="display: none" mat-stroked-button
                      (click)="mapFilterService.closeRadius()">
                    <img src="/assets/img/close.png" alt="close"/>
                </span>
                <button class="reset-button" (click)="mapFilterService.hideDistance()"
                        *ngIf="mapFilterService.displayDistance">
                    Reset
                </button>
            </div>
        </div>
    </div>
</div>
