<div class="map-filter-top">
    <div class="map-filter-left">
        <a class="route" routerLink="/dashboard" (click)="mapsService.resetMapFunc()">
            <img src="assets/img/logos/dh-logo-header.png" alt="">
        </a>
    </div>
    <div class="map-filter-select">
        <div class="input_communities" (click)="openSearch()">
            <label>
                <input type="text" placeholder="Search for projects" [(ngModel)]="searchModel"
                       (ngModelChange)="makeSearch($event)">
            </label>
            <button mat-icon-button>
                <img src="{{mapsService.showSearchBlock ? '/assets/img/filter-icons/close.svg' : '/assets/img/filter-icons/search.svg'}}"
                    alt="">
            </button>
        </div>
        <button class="wrap_close" mat-stroked-button (click)="closeFilter()">
            <img src="{{showFilterBlock ? '/assets/img/filter-icons/close.svg' : '/assets/img/filter-icons/filter-icon.svg'}}"
                 alt="close"/>
        </button>
    </div>
</div>
<div class="search-block" *ngIf="mapsService.showSearchBlock && visibleFilter">
    <div class="projects-block">
        <h4>Community</h4>
        <div class="projects-box">
            <div class="project-item"
                 *ngFor="let project of mapFilterService.listLocations, let index = index"
                 (click)="viewProject(index);">
                <div>
                    <div class="checked-icon-box">
                        <img *ngIf="index === projectIndex"
                             src="assets/img/filter-icons/checked-project-icon.svg"
                             alt="">
                    </div>
                    <span [ngStyle]="index === projectIndex && {'color': '#E27F7A'}">{{project.search_title}}</span>
                </div>
                <button *ngIf="index === projectIndex"
                        (click)="checkProjectOnList(project);">View Project
                </button>
            </div>
        </div>
    </div>
</div>
<div class="map-filter" *ngIf="showFilterBlock && visibleFilter"
     [ngClass]="{'mobile-radius':mapFilterService.displayRadius && !visibleFilter}">
    <div class="rectangle"></div>
    <div class="flex-filters">
        <div class="map-filter-property map-filter-project" *ngIf="mapsService.deviceUsed === 'mobile'">
            <h5>Developers:</h5>
            <div class="checkboxes">
                <mat-checkbox color="primary" [(ngModel)]="mapFilterService.developersFilter[1].checked"
                              [ngModelOptions]="{standalone: true}"
                              [ngClass]="{'checkbox-active': mapFilterService.developersFilter[1].checked}"
                              (ngModelChange)="mapFilterService.onValChange(2,mapFilterService.developersFilter[1].checked)">
                    Dubai Holding
                </mat-checkbox>
                <mat-checkbox color="primary" [(ngModel)]="mapFilterService.developersFilter[2].checked"
                              [ngModelOptions]="{standalone: true}"
                              [ngClass]="{'checkbox-active': mapFilterService.developersFilter[2].checked}"
                              (ngModelChange)="mapFilterService.onValChange(3,mapFilterService.developersFilter[2].checked)">
                    Dubai
                    Properties
                </mat-checkbox>
                <mat-checkbox color="primary" [(ngModel)]="mapFilterService.developersFilter[0].checked"
                              [ngModelOptions]="{standalone: true}"
                              [ngClass]="{'checkbox-active': mapFilterService.developersFilter[0].checked}"
                              (ngModelChange)="mapFilterService.onValChange(1,mapFilterService.developersFilter[0].checked)">
                    Meraas
                </mat-checkbox>
            </div>
        </div>
        <div class="map-filter-property">
            <h5>Property Type:</h5>
            <div class="checkboxes">
                <mat-checkbox color="primary"
                              [disabled]="mapsService.blockPropertyTypes"
                              [(ngModel)]="mapFilterService.propertyTypesFilter[0].checked"
                              [ngModelOptions]="{standalone: true}"
                              (ngModelChange)="checkProjectType()"
                              [ngClass]="{'disable-checkbox':mapsService.blockPropertyTypes , 'checkbox-active': mapFilterService.propertyTypesFilter[0].checked}">
                    Ready to move in
                </mat-checkbox>
                <mat-checkbox color="primary"
                              style="margin-left: 15px"
                              [disabled]="mapsService.blockPropertyTypes"
                              [(ngModel)]="mapFilterService.propertyTypesFilter[1].checked"
                              [ngModelOptions]="{standalone: true}"
                              (ngModelChange)="checkProjectType()"
                              [ngClass]="{'disable-checkbox':mapsService.blockPropertyTypes, 'checkbox-active': mapFilterService.propertyTypesFilter[1].checked}">
                    Off-plan
                </mat-checkbox>
                <mat-checkbox color="primary"
                              [(ngModel)]="mapFilterService.propertyTypesFilter[3].checked"
                              [ngModelOptions]="{standalone: true}"
                              [ngClass]="{'checkbox-active': mapFilterService.propertyTypesFilter[3].checked}"
                              (ngModelChange)="checkProjectType()">Ready to construct
                </mat-checkbox>
            </div>
        </div>
        <div class="map-filter-project-type">
            <h5>Project type:</h5>
            <div class="checkboxes">
                <mat-checkbox color="primary" [(ngModel)]="mapFilterService.projectTypeFilter[0].checked"
                              [ngModelOptions]="{standalone: true}"
                              (ngModelChange)="checkProjectType()">
                    <div class="filter-icon"
                         [ngClass]="{'filter-icon-active': mapFilterService.projectTypeFilter[0].checked}">
                        <img src="../../../../assets/img/filter-icons/{{!mapFilterService.projectTypeFilter[0].checked?'apartment':'apartment-white'}}.svg"
                             alt="">
                    </div>
                    <span class="checkbox-label">Apartments</span>
                </mat-checkbox>
                <mat-checkbox color="primary" [(ngModel)]="mapFilterService.projectTypeFilter[1].checked"
                              [ngModelOptions]="{standalone: true}"
                              (ngModelChange)="checkProjectType()">
                    <div class="filter-icon"
                         [ngClass]="{'filter-icon-active': mapFilterService.projectTypeFilter[1].checked}">
                        <img src="../../../../assets/img/filter-icons/{{!mapFilterService.projectTypeFilter[1].checked?'home':'home-white'}}.svg"
                             alt="">
                    </div>
                    <span class="checkbox-label">Townhouses</span>
                </mat-checkbox>
                <mat-checkbox color="primary" [(ngModel)]="mapFilterService.projectTypeFilter[2].checked"
                              [ngModelOptions]="{standalone: true}"
                              (ngModelChange)="checkProjectType()">
                    <div class="filter-icon"
                         [ngClass]="{'filter-icon-active': mapFilterService.projectTypeFilter[2].checked}">
                        <img src="assets/img/filter-icons/{{!mapFilterService.projectTypeFilter[2].checked?'land':'land-white'}}.svg"
                             alt=""></div>
                    <span class="checkbox-label">Land plots</span>
                </mat-checkbox>
            </div>
        </div>
        <div class="radius-block" *ngIf="mapsService.deviceUsed === 'mobile'">
            <div class="radius-box">
                <span>Radius</span>
                <div class="radius-box-slider">
                    <mat-slider class="example-margin"
                                [disabled]="mapFilterService.displayDistance || mapFilterService.disabled"
                                [invert]="mapFilterService.invert"
                                [max]="mapFilterService.max"
                                [min]="mapFilterService.min"
                                [step]="mapFilterService.step"
                                [thumbLabel]="mapFilterService.thumbLabel"
                                [(ngModel)]="mapFilterService.radiusSelected"
                                (ngModelChange)="mapFilterService.radiusSlider()"
                                [vertical]="mapFilterService.vertical"
                                aria-labelledby="example-name-label">
                    </mat-slider>
                    <label class="example-value-label">{{mapFilterService.radiusSelected}} KM</label>
                </div>
            </div>
            <div class="distance-box">
                <span>Show Distance</span>
                <mat-slide-toggle
                        color="primary"
                        [(ngModel)]="mapFilterService.displayDistance"
                        [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="checkProjectType();mapFilterService.checkIfPropertyShow()"
                        [disabled]="mapFilterService.displayRadius || projectDetailsOpen"
                        class="radius">
                </mat-slide-toggle>
            </div>
        </div>
        <div class="map-filter-plots">
            <h5>Infrastructure:</h5>
            <div class="checkboxes">
                <mat-checkbox color="primary" class="checkboxes-wrap"
                              [(ngModel)]="mapFilterService.plotTypeFilter[0].checked"
                              [ngModelOptions]="{standalone: true}" (ngModelChange)="checkProjectType()">
                    <div class="checkboxes-wrap-item">
                        <div class="filter-icon"
                             [ngClass]="{'filter-icon-active': mapFilterService.plotTypeFilter[0].checked}">
                            <img src="assets/img/filter-icons/{{!mapFilterService.plotTypeFilter[0].checked?'star':'star-white'}}.svg"
                                 alt="">
                        </div>
                        <span class="checkbox-label">Landmarks</span>
                    </div>
                </mat-checkbox>
                <mat-checkbox color="primary" class="checkboxes-wrap"
                              [(ngModel)]="mapFilterService.plotTypeFilter[4].checked"
                              [ngModelOptions]="{standalone: true}" (ngModelChange)="checkProjectType()">
                    <div class="filter-icon"
                         [ngClass]="{'filter-icon-active': mapFilterService.plotTypeFilter[4].checked}">
                        <img src="assets/img/filter-icons/{{!mapFilterService.plotTypeFilter[4].checked? 'bus':'bus-white'}}.svg"
                             alt=""></div>
                    <span class="checkbox-label">Transport</span>
                </mat-checkbox>
                <mat-checkbox color="primary" class="checkboxes-wrap"
                              [(ngModel)]="mapFilterService.plotTypeFilter[1].checked"
                              [ngModelOptions]="{standalone: true}" (ngModelChange)="checkProjectType()">
                    <div class="filter-icon"
                         [ngClass]="{'filter-icon-active': mapFilterService.plotTypeFilter[1].checked}">
                        <img src="assets/img/filter-icons/{{!mapFilterService.plotTypeFilter[1].checked? 'graduation':'graduation-white'}}.svg"
                             alt="">
                    </div>
                    <span class="checkbox-label">Education</span>
                </mat-checkbox>
                <mat-checkbox color="primary" class="checkboxes-wrap"
                              [(ngModel)]="mapFilterService.plotTypeFilter[2].checked"
                              [ngModelOptions]="{standalone: true}" (ngModelChange)="checkProjectType()">
                    <div class="filter-icon"
                         [ngClass]="{'filter-icon-active': mapFilterService.plotTypeFilter[2].checked}">
                        <img src="assets/img/filter-icons/{{!mapFilterService.plotTypeFilter[2].checked? 'shopping-cart':'shopping-cart-white'}}.svg"
                             alt=""></div>
                    <span class="checkbox-label">Shopping</span>
                </mat-checkbox>
                <mat-checkbox color="primary" class="checkboxes-wrap"
                              [(ngModel)]="mapFilterService.plotTypeFilter[3].checked"
                              [ngModelOptions]="{standalone: true}" (ngModelChange)="checkProjectType()">
                    <div class="filter-icon"
                         [ngClass]="{'filter-icon-active': mapFilterService.plotTypeFilter[3].checked}">
                        <img src="assets/img/filter-icons/{{!mapFilterService.plotTypeFilter[3].checked? 'heartbeat':'heartbeat-white'}}.svg"
                             alt=""></div>
                    <span class="checkbox-label">Health</span>
                </mat-checkbox>
            </div>
        </div>
        <div class="clean-box" (click)="mapFilterService.resetToDefault();checkProjectType();">
            <img src="../../../../assets/img/filter-icons/clear-icon.svg" alt="">
            <span>CLEAR</span>
        </div>
    </div>
</div>
