import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'hundredth'
})
export class HundredthPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): unknown {
        let changedValue;
        let includes;
        if (value.includes('sq.ft.')) {
            includes = 'sq.ft.';
        } else if (value.includes('SQ.FT.')) {
            includes = 'SQ.FT.';
        } else if (value.includes('sq.ft')) {
            includes = 'sq.ft';
        } else {
            includes = 'SQ.FT';
        }

        if (value.includes(includes)) {
            let rangedValue;
            let rangedValueBefore;
            let rangedValueAfter;
            rangedValue = value.replace(includes, '').replace(' ', '');
            rangedValueBefore = rangedValue.includes('–') ? rangedValue.split('–')[0] : rangedValue;
            rangedValueAfter = rangedValue.includes('–') ? rangedValue.split('–')[1] : null;

            if (rangedValueBefore.includes(',') != null) {
                rangedValueBefore = rangedValueBefore.replace(',', '');
            }
            if (rangedValueAfter != null && rangedValueAfter.includes(',') != null) {
                rangedValueAfter = rangedValueAfter.replace(',', '');
            }
            if (rangedValueAfter != null) {
                changedValue = `sq.ft. ${parseFloat(rangedValueBefore).toLocaleString('en')} - ${parseFloat(rangedValueAfter).toLocaleString('en')}`;
            } else {
                changedValue = `sq.ft. ${parseFloat(rangedValueBefore).toLocaleString('en')}`;
            }
        } else {
            changedValue = parseFloat(value).toLocaleString('en');
        }
        return changedValue;
    }

}
