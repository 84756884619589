import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MapsService} from 'src/app/core/services/maps.service';
import {MapFilterService} from '../../../core/services/map-filter.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ProjectsModel} from '../../../models/ProjectsModel';
import {MapsDataService} from '../../../core/services/maps-data.service';
import {Subject} from "rxjs";

@Component({
    selector: 'app-map-filter',
    templateUrl: './map-filter.component.html',
    styleUrls: ['./map-filter.component.scss'],
})
export class MapFilterComponent implements OnInit, OnChanges {
    @Output() filterEvent = new EventEmitter();
    @Output() showCommunity = new EventEmitter();
    @Output() projectDetailsCheckedOut = new EventEmitter();
    @Input() projectDetailsOpen: boolean;
    @Input() projectTitle: string;
    @Input() projectsData;
    @Input() displayRadius: boolean;
    @Input() displayDistance: boolean;
    @Input() visibleFilter: boolean;
    public showFilterBlock = true;
    public searchModel: string;
    public projectIndex;
    public searchChanged: Subject<string> = new Subject<string>();
    public filterPropertiesDefault = {
        project_filter: [2, 1, 3],
        project_type: [1, 2, 3],
        property_type: [3, 4],
        show_radius: false,
        show_distance: false
    };

    constructor(
        public dialog: MatDialog,
        public mapsService: MapsService,
        public mapFilterService: MapFilterService,
        public mapsDataService: MapsDataService,
    ) {
    }

    ngOnInit(): void {
        this.mapFilterService.filterEventSubject.subscribe(data => {
            this.filterEvent.next(data);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.projectsData != null && changes.projectsData.previousValue == null && changes.projectsData.currentValue != null) {
            this.mapFilterService.projectsData = this.projectsData;
            if (localStorage.getItem('filters') != null) {
                this.mapFilterService.filterProperties = JSON.parse(localStorage.getItem('filters'));
                this.setSavedParameters();
            } else {
                this.mapFilterService.filterProperties = this.filterPropertiesDefault;
                this.setSavedParameters();
            }
            this.mapFilterService.listLocations = this.getLocations;
            this.mapFilterService.listLocations = this.mapFilterService.listLocations.sort((a, b) => a.search_title.localeCompare(b.search_title));

            this.searchChanged
                .pipe(debounceTime(300), distinctUntilChanged())
                .subscribe((searchString) => {
                    if (!searchString) {
                        this.mapFilterService.listLocations = this.getLocations;
                    } else {
                        this.mapFilterService.listLocations = this.getLocations.filter(
                            (item: ProjectsModel) =>
                                item.title.toLowerCase().indexOf(searchString.toLowerCase()) > -1,
                        );
                    }
                });
        }
        this.setChanges();
    }

    get getLocations(): ProjectsModel[] {
        return this.projectsData.filter((item: ProjectsModel) => {
                const filters = JSON.parse(localStorage.getItem('filters'));
                if (filters.project_type.includes(item.type)) {
                    if (filters.project_filter.includes(item.project) || item.project == null) {
                        return item;
                    }
                }
            }
        );
    }

    viewProject(index): void {
        this.mapsService.showProjectDetails = false;
        this.projectIndex = index;
    }

    checkProjectOnList(project): void {
        this.mapsService.showSearchBlock = false;
        this.mapsService.openCommunityPage(project);
        this.mapsDataService.getProject(project.pk).subscribe((resp) => {
            this.projectDetailsCheckedOut.emit(resp);
        });
    }

    makeSearch(searchValue): void {
        if (!this.mapsService.showSearchBlock) {
            this.mapsService.showSearchBlock = true;
            this.showFilterBlock = false;
        }
        this.searchChanged.next(searchValue);
    }

    setChanges(): void {
        this.filterPropertiesDefault.show_distance = this.mapFilterService.displayDistance;
        this.filterPropertiesDefault.show_radius = this.mapFilterService.displayRadius;
    }

    checkProjectType(): void {
        this.filterEvent.next(this.mapFilterService.makeAllFilters());
    }

    setSavedParameters(): void {
        this.mapFilterService.displayRadius = this.mapFilterService.filterProperties.show_radius;
        this.mapFilterService.displayDistance = this.mapFilterService.filterProperties.show_distance;
        this.mapFilterService.setProjectFilters();
        if (this.mapFilterService.filterProperties.project_filter[0] == null) {
            this.mapFilterService.developersFilter.forEach(item => item.checked = true);
        }
        if (this.mapFilterService.filterProperties.property_type[0] == null) {
            this.mapFilterService.propertyTypesFilter.forEach(item => item.checked = true);
        } else {
            this.mapFilterService.filterProperties.property_type.forEach(item => {
                this.mapFilterService.propertyTypesFilter.forEach(type => {
                    if (item === type.value) {
                        type.checked = true;
                    }
                });
            });
        }
        this.mapFilterService.filterProperties.project_type.forEach((item) => {
            this.mapFilterService.projectTypeFilter.forEach(type => {
                if (item === type.value) {
                    type.checked = true;
                }
            });
            this.mapFilterService.plotTypeFilter.forEach(type => {
                if (item === type.value) {
                    type.checked = true;
                }
            });
        });
        this.mapFilterService.displayRadius = this.mapFilterService.filterProperties.show_radius = this.mapFilterService.displayRadius;
        this.mapFilterService.displayDistance = this.mapFilterService.filterProperties.show_distance = this.mapFilterService.displayDistance;
        this.filterEvent.next(this.mapFilterService.makeAllFilters());
    }

    setAllLandPlotsFilter(): void {
        Object.keys(this.mapFilterService.plotTypeFilter).forEach((key) => {
            this.mapFilterService.plotTypeFilter[key].checked = this.mapFilterService.allPlotsChecked;
        });
        this.mapFilterService.plotTypeFilter[4].checked = this.mapFilterService.allPlotsChecked;
        this.filterEvent.next(this.mapFilterService.makeAllFilters());
    }

    public closeFilter(): void {
        this.showFilterBlock = !this.showFilterBlock;
        this.mapsService.showSearchBlock = false;
        this.showCommunity.emit(false);
    }

    public openSearch(): void {
        if (this.mapsService.showSearchBlock) {
            this.mapsService.showSearchBlock = false;
            this.showFilterBlock = true;
        } else {
            this.mapsService.showSearchBlock = true;
            this.showFilterBlock = false;
        }
        this.showCommunity.emit(false);
    }
}
