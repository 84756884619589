<div class="gallery-modal"
     [ngClass]="{'gallery-modal-full': fullScreen && mapsService.deviceUsed === 'desktop',
      'tablet-version': mapsService.deviceUsed === 'tablet',
      'gallery-modal-full-mobile': mapsService.deviceUsed === 'mobile' && fullScreen}"
     [ngStyle]="mapsService.deviceUsed === 'mobile' && fullScreen &&{'height':mapsService.deviceHeight}">
    <img class="close-btn" src="./assets/img/close.png" alt="close" (click)="CloseDialog()"/>
    <div class="modal" mat-dialog-content>
        <div class="wrap_carousel">
            <carousel
                    [images]="slides"
                    [loop]="true"
                    [cellsToShow]="1"
                    [dots]="true"
                    [autoplay]="false"
                    [objectFit]="'contain'"
                    overflowCellsLimit="1">
            </carousel>
            <div class="full-screen">
                <img class="full-screen-img"
                     src="./assets/img/fullscreen-icon.png"
                     alt="Full Screen"
                     (click)="openFullScreen()">
            </div>
        </div>
    </div>
</div>
