export enum LocationTypes {
    Apartments = 1,
    Townhouses = 2,
    LandPlots = 3,
    Landmark = 4,
    Education = 5,
    Shopping = 6,
    Health = 7,
    Transport = 8,
}

export enum LocationPropertyType {
    ReadyMoveIn = 1,
    OffPlan = 2,
    LandPlots = 3,
    ReadyToConstruct = 4
}
export enum ProjectType {
    Meraas = 1,
    DH = 2,
    DP = 3,
}
