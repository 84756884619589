import {BehaviorSubject, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ProjectsInfoModel, ProjectsModel} from '../../models/ProjectsModel';
import {LocationPropertyType} from '../../models/locationTypes';

@Injectable({
    providedIn: 'root'
})
export class MapsService {
    public mobileMenu = new Subject();
    public selectedFilter = [2, 3, 1];
    public radiusRange = new BehaviorSubject(15000);
    public projectDetailsOpen = false;
    public selectedMarker;
    public markers: google.maps.Marker[] = [];
    public map: google.maps.Map;
    public deviceUsed = 'desktop';
    public deviceHeight;
    public resetMap = new Subject();
    public polygonsArray = [];
    public trafficArr = [];
    public showFiller = false;
    public blurShow = false;
    public blockPropertyTypes = false;
    public showProjectDetails = false;
    public showSearchBlock = false;
    public polygonItem;

    constructor(
        private router: Router,
    ) {
    }

    setPolygons(location: any): void {
        const polygonsLocation = location.map((locs: ProjectsInfoModel) => {
            return locs.locations;
        });
        this.polygonItem = new google.maps.Polygon({
            paths: polygonsLocation,
            strokeColor: '#3b8ae8',
            strokeWeight: 1,
            fillColor: 'rgb(128,179,255)',
            fillOpacity: 0.1,
        });
        this.polygonItem.setMap(this.map);
    }

    public openCommunityPage(location: ProjectsModel): void {
        this.projectDetailsOpen = true;
        this.markers.forEach((item: any) => {
            if (item != null && item.labelContent === location.title) {
                this.setActiveMarker(item);
            }
        });
        const centerPoint = new google.maps.LatLng(location.lat, location.lng);
        this.map.setCenter(centerPoint);
        setTimeout(() => {
            this.map.setZoom(15);
        }, 150);
        if (this.deviceUsed === 'mobile') {
            this.mobileMenu.next(false);
        }
        this.router.navigate(['dashboard', location.pk], {
            queryParams: {
                projectId: location.pk,
                propertyType: location.property_type,
                projectFilter: location.project,
                projectType: location.type
            },
        });
    }

    // markers methods
    setActiveMarker(marker): void {
        this.setDefaultMarker(this.selectedMarker);
        if (marker != null) {
            if (marker.icon.url.includes('apartments.svg')) {
                marker.setIcon({url: 'assets/img/map/apartments-active.svg'});
            } else if (marker.icon.url.includes('land-plot-marker.svg')) {
                marker.setIcon({url: 'assets/img/map/land-plot-marker-active.svg'});
            } else if (marker.icon.url.includes('townhouses')) {
                marker.setIcon({url: 'assets/img/map/townhouses-active.svg'});
            } else if (marker.icon.url.includes('education.svg')) {
                marker.setIcon({url: 'assets/img/map/education-active.svg'});
            } else if (marker.icon.url.includes('health.svg')) {
                marker.setIcon({url: 'assets/img/map/health-active.svg'});
            } else if (marker.icon.url.includes('shop.svg')) {
                marker.setIcon({url: 'assets/img/map/shop-active.svg'});
            } else if (marker.icon.url.includes('green_bus.svg')) {
                marker.setIcon({url: 'assets/img/map/green_bus-active.svg'});
            } else if (marker.icon.url.includes('red_bus.svg')) {
                marker.setIcon({url: 'assets/img/map/red_bus-active.svg'});
            } else if (marker.icon.url.includes('green_subway.svg')) {
                marker.setIcon({url: 'assets/img/map/green_subway-active.svg'});
            } else if (marker.icon.url.includes('red_subway.svg')) {
                marker.setIcon({url: 'assets/img/map/red_subway-active.svg'});
            }
            this.selectedMarker = marker;
        }
    }

    setDefaultMarker(marker): void {
        if (marker != null) {
            if (marker.icon.url.includes('apartments-active.svg')) {
                marker.setIcon({url: 'assets/img/map/apartments.svg'});
            } else if (marker.icon.url.includes('land-plot-marker-active.svg')) {
                marker.setIcon({url: 'assets/img/map/land-plot-marker.svg'});
            } else if (marker.icon.url.includes('townhouses-active.svg')) {
                marker.setIcon({url: 'assets/img/map/townhouses.svg'});
            } else if (marker.icon.url.includes('education-active.svg')) {
                marker.setIcon({url: 'assets/img/map/education.svg'});
            } else if (marker.icon.url.includes('health-active.svg')) {
                marker.setIcon({url: 'assets/img/map/health.svg'});
            } else if (marker.icon.url.includes('shop-active.svg')) {
                marker.setIcon({url: 'assets/img/map/shop.svg'});
            } else if (marker.icon.url.includes('green_bus-active.svg')) {
                marker.setIcon({url: 'assets/img/map/green-bus.svg'});
            } else if (marker.icon.url.includes('red_bus-active.svg')) {
                marker.setIcon({url: 'assets/img/map/red_bus.svg'});
            } else if (marker.icon.url.includes('green_subway-active.svg')) {
                marker.setIcon({url: 'assets/img/map/green_subway.svg'});
            } else if (marker.icon.url.includes('red_subway-active.svg')) {
                marker.setIcon({url: 'assets/img/map/red_subway.svg'});
            }
            this.selectedMarker = null;
        }
    }

    getPropertyType(property: number): string {
        let propertyType;
        if (property === LocationPropertyType.ReadyMoveIn) {
            propertyType = 'Ready To Move In';
        } else if (property === LocationPropertyType.OffPlan) {
            propertyType = 'Off Plan';
        } else if (property === LocationPropertyType.LandPlots) {
            propertyType = 'Land Plots';
        } else if (property === LocationPropertyType.ReadyToConstruct) {
            propertyType = 'Ready To Construct';
        }
        return propertyType;
    }

    public resetMapFunc(): void {
        this.blurShow = false;
        this.map.setZoom(11);
        this.map.setCenter({lat: 25.187131909260856, lng: 55.27587715093256});
    }
}
