<div class="virtual-modal"
     [ngClass]="{'virtual-modal-full': fullScreen && mapsService.deviceUsed === 'desktop',
     'virtual-modal-mobile':  mapsService.deviceUsed === 'mobile',
      'virtual-modal-full-mobile': mapsService.deviceUsed === 'mobile' && fullScreen}"
     [ngStyle]="mapsService.deviceUsed === 'mobile' && fullScreen &&{'height':mapsService.deviceHeight}">
<!--    <div class="wrap">-->
<!--        <div class="wrap_degrees">-->
<!--            <img src="assets/img/virtual-tour-icon.svg" alt="360 Degrees"/>-->
<!--        </div>-->
        <button class="wrap_close" [autofocus]="false" mat-button mat-dialog-close>
            <img src="./assets/img/close.png" alt="close"/>
        </button>
<!--    </div>-->
    <mat-dialog-content class="mat-typography">
        <div class="wrap-iframe">
            <iframe class="wrap-iframe-item" [src]="virtualTour" frameborder="0"></iframe>
        </div>
        <div class="full-screen">
            <img class="full-screen-img" src="./assets/img/fullscreen-icon.png" alt="Full Screen"
                 (click)="fullScreen = !fullScreen">
        </div>
    </mat-dialog-content>
</div>

