import {AfterViewInit, Component, HostListener} from '@angular/core';
import {MapsService} from './core/services/maps.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    title = 'app-meraas';

    constructor(public mapsService: MapsService) {
    }

    ngAfterViewInit(): void {
        const height = window.innerHeight;
        this.onResize(height);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?): void {
        const innerHeight = typeof event === 'number' ? event : event.target.innerHeight;
        if (event) {
            this.mapsService.deviceHeight = innerHeight;
            if (innerHeight < 450) {
                this.mapsService.deviceUsed = 'mobile';
            } else {
                this.getDevice();
            }
        }
    }

    getDevice(): void {
        if (/Android|iPhone|iPod|Opera Mini/i.test(navigator.userAgent)) {
            this.mapsService.deviceUsed = 'mobile';
        } else if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) {
            this.mapsService.deviceUsed = 'tablet';
        } else {
            this.mapsService.deviceUsed = 'desktop';
        }
    }
}
