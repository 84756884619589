import {MapsService} from '../../core/services/maps.service';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {MapsDataService} from '../../core/services/maps-data.service';
import {ProjectsModel} from '../../models/ProjectsModel';
import {MapFilterService} from '../../core/services/map-filter.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
    public displayRadius = false;
    public displayTraffic = false;
    public displayDistance = false;
    public visibleFilter = false;
    public title = 'Search for project...';
    public locations: any = [];
    public projectsData: ProjectsModel[];
    public projectData: ProjectsModel[];
    public locationDetails: any;
    // Event subscriber to handle unsubscribe correctly on component destroy
    private routerEventsSubscriber: any;
    public copyLocations: any;
    public developersType: number;
    public mapOptions = {
        center: {lat: 25.133909, lng: 55.280724},
        zoom: 12,
        mapId: '3446fdb3d6768937',
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scaleControl: false,
    } as google.maps.MapOptions;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public mapsService: MapsService,
        public mapsDataService: MapsDataService,
        public mapFilterService: MapFilterService,
    ) {
    }


    ngOnInit(): void {
        this.displayRadius = this.mapFilterService.displayRadius;
        this.displayDistance = this.mapFilterService.displayDistance;
        this.visibleFilter = true;
        // Listen to router events for correct location card handling
        this.routerEventsSubscriber = this.router.events.subscribe((event: any) => {
            // Invoke navigation check only on NavigationEnd
            if (event instanceof NavigationEnd) {
                this.openModals();
            }
        });
        this.mapsDataService.getProjectsData(null);
        this.mapsDataService.projectDataSubject.subscribe((data: any) => {
            this.projectsData = data;
            this.copyLocations = data;
            if (this.route.snapshot.queryParams.projectId) {
                this.openModals();
            }
        });
        this.mapsService.mobileMenu.subscribe((event: any) => {
            this.visibleFilter = event;
        });
    }

    openModals(): void {
        this.mapsService.showProjectDetails = false;
        if (this.mapsService.deviceUsed === 'tablet') {
            this.visibleFilter = false;
        }
        // queryParams always exist on 'route.snapshot'
        const id: string = this.route.snapshot.queryParams.id;
        const trafficId: string = this.route.snapshot.queryParams.trafficId;
        const projectId: string = this.route.snapshot.queryParams.projectId;
        if (projectId) {
            this.developersType = this.projectsData.find((element: any) => element.pk === +projectId).project;
            this.mapsService.showProjectDetails = true;
        } else if (this.projectData) {
            // If object found, card will be shown due to valid data, otherwise card will be hidden.
            const arr: any = trafficId == null ? this.projectsData : this.mapsService.trafficArr;
            const locId = trafficId == null ? id : trafficId;
            this.locationDetails = arr.find((c) => c.id + '' === locId);
        }
    }

    isDisplayTrafficOutFunc(event): void {
        this.displayTraffic = false;
    }

    isDisplayRadiusOutFunc(event): void {
        this.displayRadius = false;
    }

    isDisplayDistanceOutFunc(event): void {
        this.displayDistance = false;
    }

    projectDetailsOpenOutFunc(event): void {
        this.mapsService.showProjectDetails = false;
        this.locationDetails = null;
    }

    projectDetailsCheckedOut(event): void {
        this.projectData = event;
    }

    ngOnDestroy(): void {
        // Always unsubscribe from all listeners and subscriptions to avoid duplicated calls.
        if (this.routerEventsSubscriber) {
            this.routerEventsSubscriber.unsubscribe();
        }
    }

    filterEvent(filterEv: { locations: ProjectsModel[]; traffic: boolean; radius: boolean; distance: boolean }): void {
        setTimeout(() => {
            const filterEvent = this.mapFilterService.filterEvent;
            if (this.mapsService.showProjectDetails) {
                const filters = JSON.parse(localStorage.getItem('filters'));
                this.mapsService.showProjectDetails = this.hideCommunityDetails(filters);
            }
            this.locations = filterEvent.locations;
            this.displayTraffic = filterEvent.traffic;
            this.displayRadius = filterEvent.radius;
            this.displayDistance = filterEvent.distance;
        });
    }

    hideCommunityDetails(filters): boolean {
        const params = this.route.snapshot.queryParams;
        if (!filters.project_type.includes(+params.projectType)
            || !filters.property_type.includes(+params.propertyType)
            || !filters.project_filter.includes(+params.projectFilter)) {
            this.router.navigate(['dashboard']);
            return false;
        }
    }

    showCommunity(event): void {
        this.mapsService.showProjectDetails = event;
        this.visibleFilter = !event;
    }

    openOriginSite(): void {
        window.open('https://visengine.com/interactive-masterplan-application/', '_blank');
    }
}
