import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LocationTypes} from '../../models/locationTypes';
import MarkerWithLabel from '@googlemaps/markerwithlabel';
import MarkerClusterer from '@google/markerclustererplus';
import {MapsService} from '../../core/services/maps.service';
import {ProjectsModel} from '../../models/ProjectsModel';
import {MapFilterService} from '../../core/services/map-filter.service';
import {MapsDataService} from '../../core/services/maps-data.service';

@Directive({
    selector: '[appMapInitDirective]',
})
export class MapInitDirectiveDirective implements OnChanges {
    @Input() appMapInitDirective: any = [];
    @Input() projectsData: ProjectsModel[] = [];
    @Input() mapOptions: google.maps.MapOptions;
    @Input() isDisplayTraffic: boolean;
    @Input() isDisplayRadius: boolean;
    @Input() isDisplayDistance: boolean;
    @Input() projectDetailsOpen: boolean;
    @Output() isDisplayTrafficOut = new EventEmitter();
    @Output() isDisplayRadiusOut = new EventEmitter();
    @Output() isDisplayDistanceOut = new EventEmitter();
    @Output() projectDetailsOpenOut = new EventEmitter();
    @Output() projectDetailsCheckedOut = new EventEmitter();
    private infoWindow: google.maps.InfoWindow;
    private transitLayer: google.maps.TransitLayer;
    private circle: google.maps.Circle;
    private directionsService;
    private markerCluster: MarkerClusterer;
    private directionModal: google.maps.InfoWindow;
    private directionsRenderer;
    private distancePoints: {
        firstPoint: google.maps.LatLng;
        secondPoint: google.maps.LatLng;
    } = {
        firstPoint: null,
        secondPoint: null,
    };
    public markersClustering = [];
    public radiusRange;
    public locationRadius;
    public zoomLevel = 11;
    public loc;
    public selectedMarkerTraffic = null;
    public activeMarker = null;
    public comparisonArray = [];
    public arrayMobileInfoWindow = [];
    public zoomChanged = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private elementRef: ElementRef,
        private mapsService: MapsService,
        public mapFilterService: MapFilterService,
        public mapsDataService: MapsDataService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.appMapInitDirective != null
            && changes.appMapInitDirective.previousValue == null
            && changes.appMapInitDirective.currentValue != null) {
            setTimeout(() => {
                if (google) {
                    this.directionsService = new google.maps.DirectionsService();
                    this.directionsRenderer = new google.maps.DirectionsRenderer({
                        suppressMarkers: false,
                    });
                    this.initMap();
                }
            }, 100);
        }

        if (this.mapsService.map != null) {
            if ((changes.projectsData && changes.projectsData.previousValue !== changes.projectsData.currentValue)
                || changes.projectDetailsOpen && changes.projectDetailsOpen.currentValue) {
                if (changes.projectsData && changes.projectsData.previousValue !== changes.projectsData.currentValue) {
                    this.zoomChanged = true;
                }
                this.getMarkers();
            }
            if (changes.isDisplayRadius != null && !changes.isDisplayRadius.currentValue && !this.mapFilterService.displayRadius) {
                this.hideRadius();
                this.getMarkers();
            }

            if (changes.isDisplayDistance != null && !changes.isDisplayDistance.currentValue && !this.mapFilterService.displayDistance) {
                this.deleteDistance();
                this.getMarkers();
            }
            if (changes.projectDetailsOpen != null &&
                changes.projectDetailsOpen.currentValue === false &&
                changes.projectDetailsOpen.previousValue === true ||
                (changes.isDisplayRadius != null && this.mapFilterService.displayRadius != null &&
                    changes.isDisplayRadius.currentValue === false)) {
                this.displayRadius({});
            }
            this.deleteDistanceRightClick();
        }
    }

    mobileRadius(): void {
        if (this.mapsService.deviceUsed === 'mobile') {
            if (this.radiusRange === 15000) {
                this.mapsService.map.setZoom(11);
            } else if (this.radiusRange >= 25000) {
                this.mapsService.map.setZoom(10);
            }
        }
    }

    initMap(): void {
        this.appMapInitDirective = this.appMapInitDirective.sort((a, b) => +a.lat - +b.lat || +a.lng - +b.lng);
        this.mapsService.map = new google.maps.Map(
            this.elementRef.nativeElement,
            this.mapOptions
        );
        setTimeout(() => {
            this.mapsService.map.setZoom(11);
            this.mapsService.map.setCenter({lat: 25.187131909260856, lng: 55.27587715093256});
        }, 0);

        this.hideAllElements(true);
        this.infoWindow = new google.maps.InfoWindow();
        this.displayRadius({});
        this.directionsRenderer.setMap(this.mapsService.map);
        this.mapsService.radiusRange.subscribe(range => {
            this.radiusRange = range;
            if (this.radiusRange >= 20000) {
                this.mapsService.map.setZoom(11);
            } else if (this.radiusRange >= 10000) {
                this.mapsService.map.setZoom(12);
            } else if (this.radiusRange >= 5000) {
                this.mapsService.map.setZoom(13);
            }
            this.mobileRadius();
            if (this.locationRadius != null) {
                this.displayRadius(this.locationRadius);
            }
        });
        google.maps.event.addListener(this.mapsService.map, 'zoom_changed', () => {
            if (this.zoomLevel !== this.mapsService.map.getZoom()) {
                this.zoomChanged = true;
                this.zoomLevel = this.mapsService.map.getZoom();
                if (this.zoomLevel < 5) {
                    setTimeout(() => {
                        this.mapsService.map.setZoom(11);
                        this.mapsService.map.setCenter({lat: 25.187131909260856, lng: 55.27587715093256});
                    });
                }
                if (this.zoomLevel >= 9 && !this.projectDetailsOpen) {
                    this.activeMarker = null;
                }
                this.deleteMarkers();
                this.getMarkers();
            }
        });
        this.mapsService.map.addListener('click', () => {
            if (this.mapFilterService.displayRadius
                || this.projectDetailsOpen
                || this.mapFilterService.displayDistance
                || this.route.snapshot.queryParams.id
                || this.selectedMarkerTraffic) {
                this.hideAllElements(false);
            }
            if (this.arrayMobileInfoWindow[0] != null) {
                this.arrayMobileInfoWindow[0].close();
                setTimeout(() => {
                    this.mapsService.setDefaultMarker(this.activeMarker);
                }, 100);
            }
        });
        this.mapsService.resetMap.subscribe((event) => {
            if (event === 'distance') {
                this.deleteDistance();
            }
            this.hideAllElements(true);
        });
    }

    hideAllElements(zoom): void {
        if (this.mapsService.selectedMarker != null) {
            this.mapsService.setDefaultMarker(this.mapsService.selectedMarker);
        }
        if (this.mapFilterService.displayRadius) {
            this.hideRadius();
            this.isDisplayRadiusOut.next(false);
        }
        if (this.projectDetailsOpen) {
            this.projectDetailsOpen = false;
            this.projectDetailsOpenOut.next(false);
        }
        if (this.mapFilterService.displayDistance) {
            if (this.distancePoints.secondPoint) {
                this.deleteDistance();
            }
            this.isDisplayDistanceOut.next(false);
        }
        if (this.selectedMarkerTraffic) {
            this.mapsService.setDefaultMarker(this.selectedMarkerTraffic);
        }
        this.projectDetailsOpenOut.next(false);
        this.router.navigate(['dashboard']);
        if (zoom) {
            this.mapsService.map.setZoom(11);
        }
    }

    markerClickHandler(marker: any, location: ProjectsModel): void {
        if (this.mapsService.selectedMarker != null) {
            this.hideAllElements(false);
            setTimeout(() => {
                this.setActiveMarker(marker, location);
            }, 0);
        } else {
            this.setActiveMarker(marker, location);
        }
    }

    setActiveMarker(marker: any, location: ProjectsModel): void {
        if (this.mapFilterService.displayDistance || this.mapFilterService.displayRadius) {
            if (this.mapFilterService.displayDistance) {
                this.hideRadius();
                this.handlerDistance(marker, location);
            } else {
                this.deleteDistance();
                this.displayRadius(location);
            }
        } else {
            if (
                location.type === LocationTypes.Landmark ||
                location.type === LocationTypes.Shopping ||
                location.type === LocationTypes.Education ||
                location.type === LocationTypes.Health
            ) {
                // this.openModalWindow(location, 'landmark');
            } else {
                this.mapsDataService.getProject(location.pk).subscribe((resp) => {
                    this.projectDetailsCheckedOut.emit(resp);
                    this.mapsService.setActiveMarker(marker);
                    this.mapsService.openCommunityPage(location);
                });
            }
        }
    }

    toggleBounce(marker: google.maps.Marker): void {
        if (!marker.getAnimation()) {
            marker.setAnimation(google.maps.Animation.BOUNCE);
        } else {
            marker.setAnimation(null);
        }
    }

    getMarkerIcon(icon, type: LocationTypes): string {
        let marker;
        if (!marker) {
            if (type === 1) {
                marker = 'assets/img/map/apartments.svg';
            } else if (type === 2) {
                marker = 'assets/img/map/townhouses.svg';
            } else if (type === 3) {
                marker = 'assets/img/map/land-plot-marker.svg';
            } else if (type === 4) {
                if (icon == null) {
                    marker = 'assets/img/map/landmarks.svg';
                } else {
                    marker = icon;
                }
            } else if (type === 5) {
                marker = 'assets/img/map/education.svg';
            } else if (type === 6) {
                marker = 'assets/img/map/shop.svg';
            } else if (type === 7) {
                marker = 'assets/img/map/health.svg';
            }
        }
        return marker;
    }

    setMarker(labelContent, top, loc, location: ProjectsModel): google.maps.Marker {
        const className = 'labels-markers-text-small';
        const icon = this.getMarkerIcon(loc.marker_image, loc.type);
        const scaledSize = loc.type === 4 ? new google.maps.Size(21, loc.marker_image === 'assets/img/map/landmarks.svg' ? 20 : 25) : null;
        const topMargin = top === -51 ? -47 : top ? top : -35;
        let marker: any = null;
        if (((this.zoomLevel === 11 && location.hide_zoom) ||
                (this.zoomLevel === 10 && location.hide_zoom_large) ||
                (this.zoomLevel === 12 && location.hide_zoom_mid) && labelContent !== 'Bvlgari <br/> Residences') ||
            (this.zoomLevel < 14 && location.hide_zoom_mid && labelContent === 'Bvlgari <br/> Residences') ||
            (this.zoomLevel >= 12 && (this.zoomLevel <= 14 && location.pk !== 4 && location.pk !== 5
                && location.pk !== 29 && location.pk !== 3) && location.hide_zoom_mid) ||
            this.zoomLevel < 10 || (this.zoomLevel === 15 &&
                (location.pk === 132 || location.pk === 134 || location.pk === 136))) {
            marker = new google.maps.Marker({
                position: loc,
                map: this.mapsService.map,
                icon: {
                    url: icon,
                    scaledSize
                },
            });
        } else {
            marker = new MarkerWithLabel({
                labelContent,
                position: loc,
                map: this.mapsService.map,
                labelClass: className,
                labelAnchor: new google.maps.Point(-35, topMargin),
                icon: {
                    url: icon,
                    scaledSize
                },
            });
        }
        this.infoWindowFunction(marker, loc, location);
        return marker;
    }

    mobileIfInfoWindowOpen(marker, infoWindow, location): void {
        marker.addListener('click', () => {
            if (this.mapFilterService.displayRadius ||
                this.mapFilterService.displayDistance) {
                if (this.mapFilterService.displayDistance) {
                    this.handlerDistance(marker, location);
                } else {
                    this.deleteDistance();
                    this.displayRadius(location);
                }
            } else {
                if (this.arrayMobileInfoWindow[0]) {
                    if (this.arrayMobileInfoWindow[0] !== infoWindow) {
                        this.arrayMobileInfoWindow[0].close();
                        this.arrayMobileInfoWindow = [];
                        this.arrayMobileInfoWindow.push(infoWindow);
                        infoWindow.open(this.mapsService.map, marker);
                        setTimeout(() => {
                            document.getElementById('InfoWindow').addEventListener('click', () => {
                                this.markerClickHandler(marker, location);
                            });
                        });
                        if (this.activeMarker != null) {
                            this.mapsService.setDefaultMarker(this.activeMarker);
                        }
                        this.mapsService.setActiveMarker(marker);
                        this.activeMarker = marker;
                    } else {
                        this.arrayMobileInfoWindow[0].close();
                        this.arrayMobileInfoWindow = [];
                        this.mapsService.setDefaultMarker(this.activeMarker);
                    }
                } else {
                    this.arrayMobileInfoWindow.push(infoWindow);
                    infoWindow.open(this.mapsService.map, marker);
                    setTimeout(() => {
                        document.getElementById('InfoWindow').addEventListener('click', () => {
                            this.markerClickHandler(marker, location);
                        });
                    });
                    if (this.activeMarker != null) {
                        this.mapsService.setDefaultMarker(this.activeMarker);
                    }
                    this.mapsService.setActiveMarker(marker);
                    this.activeMarker = marker;
                }
            }
        });
    }

    checkMarker(location: ProjectsModel, filters): google.maps.Marker {
        if ((filters.project_type.includes(location.type) || location.type === 4) && location.type !== 8) {
            if (filters.project_filter.includes(location.project) || location.project == null) {
                const top = location.top != null ? location.top : -60;
                const loc = {...location};
                const labelContent = [4, 5, 6, 7, 8].includes(location.type) ? ' ' : location.title;
                if ((location.search_title.includes('Serena') || location.search_title.includes('Mudon') || location.search_title.includes('Villanova'))
                    && location.lat_def != null && this.zoomLevel === 13) {
                    loc.lat = location.lat_def;
                    loc.lng = location.lng_def;
                } else if ([1, 2, 3, 4].includes(location.type) && location.lat_mid && (this.zoomLevel === 14 || this.zoomLevel === 13)) {
                    loc.lat = location.lat_mid;
                    loc.lng = location.lng_mid;
                } else if ([1, 2, 3, 4].includes(location.type) && location.lat_def != null && this.zoomLevel <= 13) {
                    loc.lat = location.lat_def;
                    loc.lng = location.lng_def;
                }

                return this.setMarker(labelContent, top, loc, location);
            }
        }
        if (location.type === 8) {
            this.mapsService.trafficArr.push(location);
        }
    }

    infoWindowFunction(marker, loc, location): void {
        let infoWindow;
        let srcLogo = loc.logo;
        if (loc.logo == null) {
            srcLogo = loc.project === 1 ? 'assets/img/logos/meraas-logo.png' : loc.project === 2 ? 'assets/img/logos/dh-logo.png' : 'assets/img/logos/dp-logo.png';
        }
        if ([5, 6, 7, 8].includes(loc.type)) {
            infoWindow = new google.maps.InfoWindow({
                content: `<div id="infrastructure" class="infrastructure-hover-block">
                            <div class="content">
                              <img src="${loc.images[0]}" alt="">
                            </div>
                            <div class="bottom">
                                <h5>${loc.search_title}</h5>
                            </div>
                        </div>`
            });
        } else if (loc.type === 4) {
            infoWindow = new google.maps.InfoWindow({
                content: `<div id="infrastructure" class="landmark-hover-block infrastructure-hover-block">
                            <div class="content">
                              <img src="${loc.images[0]}" alt="">
                            </div>
                            <div class="bottom">
                                <h5>${loc.search_title}</h5>
                                <span>${loc.description}</span>
                            </div>
                        </div>`
            });
        } else if ([1, 2, 3].includes(loc.type)) {
            const images = loc.images[0] != null ? loc.images[0] : 'assets/img/bg-for-gelary.png';
            const propertyType = this.mapsService.getPropertyType(loc.property_type);
            infoWindow = new google.maps.InfoWindow({
                content: `<div class="project-hover-block" id="InfoWindow">
                            <div class="header">
                                <img src="${srcLogo}" alt="">
                            </div>
                            <div class="content">
                              <img src="${images}" alt="">
                            </div>
                            <div class="bottom">
                                <span>${propertyType}</span>
                                <h5>${loc.search_title}</h5>
                            </div>
                        </div>`
            });
        }
        if (this.mapsService.deviceUsed === 'mobile') {
            this.mobileIfInfoWindowOpen(marker, infoWindow, location);
        } else if (this.mapsService.deviceUsed !== 'mobile') {
            marker.addListener('click', () => {
                    this.markerClickHandler(marker, location);
                }
            );
            google.maps.event.addListener(marker, 'mouseover', () => {
                infoWindow.open(this.mapsService.map, marker);
                if (this.activeMarker != null) {
                    this.mapsService.setDefaultMarker(this.activeMarker);
                }
                setTimeout(() => {
                    this.activeMarker = marker;
                    this.mapsService.setActiveMarker(marker);
                }, 100);
            });
            google.maps.event.addListener(marker, 'mouseout', () => {
                infoWindow.close();
                if (!this.projectDetailsOpen){
                    setTimeout(() => {
                        this.mapsService.setDefaultMarker(this.activeMarker);
                    }, 100);
                }
            });
        }
        if (this.mapFilterService.displayRadius && this.activeMarker != null
            && this.activeMarker.labelContent === marker.labelContent) {
            this.mapsService.setActiveMarker(marker);
        }
        if ([1, 2, 3].includes(location.type) && this.zoomLevel <= 11) {
            this.markersClustering.push(marker);
        }
    }

    getMarkers(): void {
        if (this.zoomChanged) {
            this.zoomChanged = false;
            const filters = JSON.parse(localStorage.getItem('filters'));
            if (this.mapsService.markers[0] != null || this.markersClustering[0] != null) {
                this.deleteMarkers();
            }
            if (this.mapsService.selectedMarker && this.mapFilterService.displayRadius && this.activeMarker == null) {
                this.activeMarker = this.mapsService.selectedMarker;
            }
            this.markersClustering = [];
            this.comparisonArray = [];
            this.mapsService.polygonsArray = [];
            this.mapsService.trafficArr = [];
            this.mapsService.markers = this.appMapInitDirective.map((location: ProjectsModel) => {
                return this.checkMarker(location, filters);
            });
            this.displayTraffic();

            if (this.zoomLevel < 10) {
                this.markerCluster = new MarkerClusterer(this.mapsService.map, this.markersClustering, {
                    imagePath:
                        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                });
            }
        }
    }

    private openModalWindow(location, type): void {
        const id: string = this.route.snapshot.queryParams.id;
        if (type === 'traffic') {
            this.router.navigate(['dashboard'], {queryParams: {trafficId: location.id}});
        } else {
            if (location.id + '' === id) {
                this.router.navigate(['dashboard'], {queryParams: {} || null});
            } else {
                this.router.navigate(['dashboard'], {
                    queryParams: {id: location.id},
                });
            }
        }
    }

    private displayTraffic(): void {
        if (this.isDisplayTraffic === true) {
            if (!this.transitLayer) {
                this.transitLayer = new google.maps.TransitLayer();
            }
            this.mapsService.trafficArr.forEach((elem) => {
                const size = new google.maps.Size(22, 22);
                const marker = new google.maps.Marker({
                    position: {
                        lat: elem.lat,
                        lng: elem.lng,
                    },
                    map: this.mapsService.map,
                    icon: {url: elem.marker_image, size},
                });
                const infoWindow = new google.maps.InfoWindow({
                    content: `<div class="infrastructure-hover-block">
                            <div class="content">
                              <img src="${elem.images[0]}" alt="">
                            </div>
                            <div class="bottom">
                                <h5>${elem.title}</h5>
                            </div>
                        </div>`
                });

                if (this.mapsService.deviceUsed === 'mobile') {
                    this.mobileIfInfoWindowOpen(marker, infoWindow, 'location');
                } else {
                    marker.addListener('mouseover', () => {
                            infoWindow.open(this.mapsService.map, marker);
                            if (this.selectedMarkerTraffic != null) {
                                this.mapsService.setDefaultMarker(this.selectedMarkerTraffic);
                            }
                            setTimeout(() => {
                                this.selectedMarkerTraffic = marker;
                                this.mapsService.setActiveMarker(marker);
                            }, 100);
                        }
                    );
                    marker.addListener('mouseout', () => {
                        infoWindow.close();
                        setTimeout(() => {
                            this.mapsService.setDefaultMarker(this.selectedMarkerTraffic);
                        }, 100);
                    });
                    marker.addListener('click', () => {
                        if (this.selectedMarkerTraffic != null) {
                            this.mapsService.setDefaultMarker(this.selectedMarkerTraffic);
                        }
                        this.mapsService.setActiveMarker(marker);
                        this.selectedMarkerTraffic = marker;
                    });
                }
                this.mapsService.markers.push(marker);
            });

            this.transitLayer.setMap(this.mapsService.map);
        } else {
            if (this.transitLayer) {
                this.transitLayer.setMap(null);
            }
        }
    }

// radius methods
    private displayRadius(location): void {
        const loc = (location == null) ? this.locationRadius : {...location};
        if (this.mapFilterService.displayRadius === true && (loc.lat || loc.lng)) {
            if (this.circle) {
                this.circle.setMap(null);
            }
            const centerPoint = new google.maps.LatLng(loc.lat, loc.lng);
            this.mapsService.map.setCenter(centerPoint);
            this.locationRadius = loc;
            this.circle = new google.maps.Circle({
                center: centerPoint,
                map: this.mapsService.map,
                radius: this.radiusRange,
                strokeColor: '#727272',
                strokeOpacity: 0,
                strokeWeight: 1.3,
                fillColor: '#98D4E3',
                fillOpacity: 0.6,
            });
            this.mobileRadius();
        } else {
            if (this.circle) {
                if (this.mapsService.selectedMarker != null) {
                    this.mapsService.setDefaultMarker(this.mapsService.selectedMarker);
                }
                this.activeMarker = null;
                this.circle.setMap(null);
            }
        }
    }

    hideRadius(): void {
        if (this.circle) {
            this.circle.setMap(null);
        }
    }

// distance methods
    calculateAndDisplayRoute(
        directionsService: google.maps.DirectionsService,
        directionsRenderer: google.maps.DirectionsRenderer,
        firstPoint: google.maps.LatLng,
        secondPoint: google.maps.LatLng
    ): void {
        directionsService.route(
            {
                origin: firstPoint,
                destination: secondPoint,
                travelMode: google.maps.TravelMode.DRIVING,
            },
            (response: google.maps.DirectionsResult, status) => {
                if (status === 'OK') {
                    directionsRenderer.setDirections(response);
                    const distance = response.routes[0].legs[0].distance.text;
                    const duration = response.routes[0].legs[0].duration.text;
                    const centerPoint = Math.floor(
                        response.routes[0].overview_path.length / 2
                    );
                    this.renderDistanceInfoWindow(
                        distance,
                        duration,
                        response.routes[0].overview_path[centerPoint || 0]
                    );
                } else {
                    window.alert('Directions request failed due to ' + status);
                }
            }
        );
    }

    checkSamePoints(location: ProjectsModel): void {
        if (
            this.distancePoints.firstPoint.lat() === location.lat &&
            this.distancePoints.firstPoint.lng() === location.lng
        ) {
            this.distancePoints = {
                firstPoint: null,
                secondPoint: null,
            };
        } else {
            this.distancePoints.secondPoint = new google.maps.LatLng(
                location.lat,
                location.lng
            );
            this.calculateAndDisplayRoute(
                this.directionsService,
                this.directionsRenderer,
                this.distancePoints.firstPoint,
                this.distancePoints.secondPoint
            );
            this.stopAnimation();
        }
    }

    handlerDistance(marker, location: ProjectsModel): void {
        if (this.distancePoints.firstPoint
        ) {
            if (this.distancePoints.secondPoint) {
                this.checkSamePoints(location);
            } else {
                this.checkSamePoints(location);
            }
        } else {
            this.toggleBounce(marker);
            this.distancePoints.firstPoint = new google.maps.LatLng(
                location.lat,
                location.lng
            );
        }
    }

    renderDistanceInfoWindow(distance: string, duration: string, modalPosition: google.maps.LatLng | google.maps.LatLngLiteral): void {
        if (this.directionModal) {
            this.directionModal.close();
        }
        this.directionModal = new google.maps.InfoWindow();
        const divEl = `<div class="distance-modal"><span>${distance}</span><span>${duration}</span<</div>`;
        this.directionModal.setContent(divEl);
        this.directionModal.setPosition(modalPosition);
        this.directionModal.open(this.mapsService.map);
    }

    deleteDistance(): void {
        this.distancePoints = {
            firstPoint: null,
            secondPoint: null,
        };
        this.directionsRenderer.setMap(null);
        this.directionsRenderer.setPanel(null);
        this.directionsRenderer = new google.maps.DirectionsRenderer({
            suppressMarkers: false,
        });
        this.directionsRenderer.setMap(this.mapsService.map);
        if (this.directionModal) {
            this.directionModal.close();
        }
    }

    deleteDistanceRightClick(): void {
        if (this.mapFilterService.displayDistance) {
            google.maps.event.addListener(this.mapsService.map, 'click', () => {
                this.deleteDistance();
                this.stopAnimation();
            });
        }
    }

    private deleteMarkers(): void {
        this.mapsService.markers.forEach((marker) => {
            if (marker !== undefined) {
                marker.setMap(null);
            }
        });
        this.markersClustering.forEach((marker) => {
            if (marker !== undefined) {
                marker.setMap(null);
            }
        });
        this.mapsService.polygonsArray.forEach((item) => {
            if (item !== undefined) {
                item.polygon.setMap(null);
            }
        });
        if (this.markerCluster != null) {
            this.markerCluster.clearMarkers();
        }
    }

    private stopAnimation(): void {
        this.mapsService.markers.forEach((ele) => {
            ele.setAnimation(null);
        });
    }
}
