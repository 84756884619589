import {ActivatedRoute} from '@angular/router';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {GalleryModalComponent} from '../modals/gallery-modal/gallery-modal.component';
import {VirtualModalComponent} from '../modals/virtual-modal/virtual-modal.component';
import {Subscription} from 'rxjs';
import {MapsService} from '../../../core/services/maps.service';
import {MatAccordion} from '@angular/material/expansion';
import {ProjectsModel} from '../../../models/ProjectsModel';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl, FormGroup, Validators} from '@angular/forms';

export interface DialogData {
    imagesArray?: [];
    id: number;
    virtual_tours?: '';
    autoplay: boolean;
}

@Component({
    selector: 'app-community-info-card',
    templateUrl: './community-info-card.component.html',
    styleUrls: ['./community-info-card.component.scss'],
})
export class CommunityInfoCardComponent implements OnDestroy, OnChanges {
    @ViewChild(MatAccordion) accordion: MatAccordion;
    @Input() projectData;
    @Input() developersType: number;
    public matDialogRef: MatDialogRef<GalleryModalComponent>;
    public matDialogRefTwo: MatDialogRef<VirtualModalComponent>;
    public rangeBetween;
    public rangesShow = false;
    public propertyType = '';
    public showCommunityCard = true;
    public showMessage = false;
    public requestForm: FormGroup;
    public mobileCommunityHeight;
    private readonly routeSubscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private activateRoute: ActivatedRoute,
        public mapsService: MapsService,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private http: HttpClient,
    ) {
        this.routeSubscription = this.activateRoute.queryParams.subscribe((query?: { propertyType?: string }) => {
            if (query) {
                this.propertyType = this.mapsService.getPropertyType(+query.propertyType);
            }
        });
        this.requestForm = new FormGroup({
            full_name: new FormControl('', [Validators.required, Validators.minLength(1)]),
            phone_number: new FormControl('', [Validators.required, Validators.minLength(10)]),
            email: new FormControl('', [Validators.required, Validators.email]),
        });
    }

    public openDialog(projectData): void {
        this.mapsService.blurShow = true;
        const galleryClass = this.mapsService.deviceUsed === 'desktop' ? 'gallery-desktop-modal' : this.mapsService.deviceUsed === 'tablet' ? 'gallery-modal-tablet' : 'gallery-modal-mobile';
        this.matDialogRef = this.dialog.open(GalleryModalComponent, {
            panelClass: galleryClass,
            data: {
                imagesArray: projectData.images,
                id: projectData.id,
                autoplay: this.mapsService.deviceUsed === 'desktop'
            }
        });
        this.matDialogRef.afterClosed().subscribe(result => {
            this.mapsService.blurShow = false;
        });
    }

    public openRequestDialog(parameter): void {
        if (this.projectData.original_link) {
            window.open(this.projectData.original_link, '_blank');
        } else {
            this.showCommunityCard = !parameter;
            this.mapsService.blurShow = parameter;
        }
    }

    submitData(): void {
        if (this.requestForm.valid) {
            const formData = {
                'Project Name:': this.projectData.search_title,
                'Full Name:': this.requestForm.controls.full_name.value,
                'Phone Number:': this.requestForm.controls.phone_number.value,
                'Email:': this.requestForm.controls.email.value
            };
            const headers = new HttpHeaders({'Content-Type': 'application/json'});
            this.http.post('https://formspree.io/f/mqkwkbel',
                formData,
                {headers}).subscribe(
                () => {
                    this.showMessage = true;
                }
            );
        }
    }

    openVirtualDialog(): void {
        this.mapsService.blurShow = true;

        const data: { id: number, virtual_tours?: string } = {
            id: this.projectData.pk
        };
        if (this.projectData.virtual_tours) {
            data.virtual_tours = this.projectData.virtual_tours;
        }
        this.matDialogRefTwo = this.dialog.open(VirtualModalComponent, {
            panelClass: 'gallery-desktop-modal-virtual', data
        });
        this.matDialogRefTwo.afterClosed().subscribe(result => {
            this.mapsService.blurShow = false;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const currentValue: any = changes.projectData.currentValue;
        if (currentValue) {
            const landmarksImages = [];
            this.projectData.images.forEach((item, index) => {
                landmarksImages[index] = new Image();
                landmarksImages[index].src = item;
            });
            if (!changes.projectData.firstChange) {
                if (changes.projectData.currentValue.pk !== changes.projectData.previousValue.pk) {
                    this.projectData = changes.projectData.currentValue;
                    this.setChanges(currentValue);
                }
            } else {
                this.setChanges(currentValue);
            }
        }
    }

    setChanges(currentValue): void {
        const data: { name: string, locations?: string, description?: string } = {
            name: currentValue.search_title
        };
        if (this.projectData.locations[1]) {
            data.locations = currentValue.locations;
            data.description = currentValue.size_range_description;
            this.setRanges(data);
        } else {
            data.locations = this.projectData.locations;
            data.description = this.projectData.size_range_description;
            this.setRanges(data);
        }
    }

    setRanges(data): void {
        if (data.locations.length > 0) {
            if (data.description != null && data.description.length > 0) {
                this.rangesShow = true;
                if (data.description.includes('Ranges between:')) {
                    this.rangeBetween = data.description.split('Ranges between:')[1];
                } else {
                    this.rangeBetween = data.description;
                }
            }
            this.mapsService.setPolygons(this.projectData.locations);
        }
    }

    resetMap(): void {
        this.mapsService.resetMap.next(true);
    }

    ngOnDestroy(): void {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
        if (this.mapsService.polygonItem) {
            this.mapsService.polygonItem.setMap(null);
        }
    }
}
